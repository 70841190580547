@import-normalize; /* bring in normalize.css styles */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: "Open Sans", Arial, Tahoma, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    box-sizing: border-box;
}

a,
body,
button,
div,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
img,
li,
nav,
ol,
p,
section,
span,
table,
tbody,
td,
th,
title,
tr,
ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
