.loginContainer {
    margin: 15px;

    p {
        margin: 5px;
        font-size: 14px;
        font-weight: bold;
    }

    input {
        padding: 5px;
    }

    button {
        margin-top: 10px;
        padding: 7px 15px;
    }
}
