.aboutContainer {
    max-width: 780px;
    width: 100%;
    margin: 20px auto;
    padding: 10px;

    h2 {
        margin-top: 25px;
        text-align: center;
        text-transform: uppercase;
        font-size: 35px;
        font-weight: normal;
    }

    .slogan {
        margin: 25px 0;
        font-size: 20px;
    }

    .description {
        font-size: 14px;
        line-height: 1.5;

        a {
            text-decoration: underline;
        }
    }
}

.imageContainer {
    width: 100%;
    min-height: 240px;
    background-image: url('../../../public/cta-large.jpg');
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (min-width: 780px) {
    .aboutContainer h2 {
        font-size: 48px;
    }

    .imageContainer {
        min-height: 500px;
        background-attachment: fixed;
    }
}

@media only screen and (min-width: 1024px) {
    .aboutContainer h2 {
        font-size: 65px;
    }
}
