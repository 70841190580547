.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    color: #ffffff;
}

.loadingSpinner {
    position: absolute;
    top: 33%;
    left: 50%;
    width: 120px;
    height: 120px;
    stroke: #ffffff;
    stroke-width: 5px;
    color: #000000;
    transform: translateX(-50%);
}
