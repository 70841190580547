.container {
    display: flex;
    flex-direction: column;
    max-width: 460px;
    width: 100%;
    margin: 5px;

    .image {
        height: 220px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .title {
        margin: 10px 0;
        text-transform: uppercase;
        font-weight: normal;
    }

    .releaseDate {
        margin: 10px 0;
        font-weight: bold;
    }

    .description {
        flex-grow: 1;
        margin-bottom: 20px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: justify;
    }

    .buttons {
        margin: 10px;
    }
}

@media only screen and (min-width: 480px) {
    .container {
        margin: 10px;

        .image {
            height: 280px;
        }
    }
}

@media only screen and (min-width: 780px) {
    .container {
        margin: 15px;

        .image {
            height: 300px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .container {
        margin: 20px;

        .image {
            height: 340px;
        }
    }
}
