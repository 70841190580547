.giftInfo {
    height: 300px;
    text-align: center;

    h3 {
        text-transform: uppercase;
    }

    .chestName {
        margin: 5px 0;
        font-size: 14px;
    }

    img {
        display: inline-block;
        width: 200px;
        border-radius: 5px;
        animation: scale 2s;
        transition: all 0.2s ease-out;

        &:hover {
            transform: scale(1.2);
        }
    }

    @keyframes scale {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.3);
        }
        100% {
            transform: scale(1);
        }
    }
}
