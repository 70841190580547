.header {
    position: relative;
    width: 100%;
    max-width: 780px;
    height: 100%;
    margin: 0 auto;
    padding: 10px;
    color: #000000;

    .siteName {
        display: block;
        text-align: center;
        font-size: 24px;
        letter-spacing: normal;
    }

    hr {
        width: 75%;
    }

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li {
        margin: 0 10px;
        font-size: 13px;

        &:hover {
            text-decoration: underline;
        }
    }
}

@media only screen and (min-width: 480px) {
    .header {
        padding: 30px;
    }
}
