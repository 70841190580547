.container {
    position: relative;
    display: block;
    width: 100%;
    max-width: 780px;
    margin: 10px auto;

    img {
        width: 100%;
    }
}

.discountWrapper {
    position: absolute;
    top: 15%;
    right: 1%;
    width: 55px;
    height: 55px;

    .discountBgr {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/discount.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
    }

    .discountContainer {
        position: relative;
        width: 100%;
        height: 100%;

        .discount {
            position: absolute;
            top: 25%;
            right: 22%;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
            text-shadow: -2px -2px 0px #4e1400, 0px -2px 0px #4e1400,
                2px -2px 0px #4e1400, -2px 0px 0px #4e1400, 2px 0px 0px #4e1400,
                -2px 2px 0px #4e1400, 0px 2px 0px #4e1400, 2px 2px 0px #4e1400;

            .discountLabel {
                font-size: 8px;
            }
        }
    }
}

.animated {
    animation: rotate 2s 2s;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 320px) {
    .discountWrapper {
        width: 70px;
        height: 70px;

        .discountContainer .discount {
            font-size: 18px;

            .discountLabel {
                font-size: 9px;
            }
        }
    }
}

@media only screen and (min-width: 400px) {
    .discountWrapper {
        width: 75px;
        height: 75px;
    }
}

@media only screen and (min-width: 480px) {
    .discountWrapper {
        width: 100px;
        height: 100px;

        .discountContainer .discount {
            font-size: 25px;

            .discountLabel {
                font-size: 12px;
            }
        }
    }
}

@media only screen and (min-width: 580px) {
    .discountWrapper {
        width: 120px;
        height: 120px;

        .discountContainer .discount {
            font-size: 35px;
        }
    }
}

@media only screen and (min-width: 680px) {
    .discountWrapper {
        width: 156px;
        height: 156px;

        .discountContainer .discount {
            font-size: 44px;

            .discountLabel {
                font-size: 14px;
            }
        }
    }
}
