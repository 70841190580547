.contacts {
    width: 100%;
    max-width: 780px;
    margin: auto;
    padding: 5px 20px;
    background-color: #ffffff;

    p {
        margin: 10px 3px;
        font-size: 15px;
    }

    a {
        margin: 10px;

        svg {
            font-size: 20px;
            transition: all 0.2s ease-out;
        }

        &:hover svg {
            transform: scale(1.15);
        }

        &.footer-link {
            display: inline-block;
            margin: 14px 10px;
            font-size: 14px;
            text-decoration: underline;
        }
    }
}

.logo {
    height: 30px;
    vertical-align: middle;
    margin-bottom: 3px;
}
