.button {
    display: inline-block;
    margin: 3px 10px;
    padding: 8px 7px;
    background-color: #000000;
    color: #ffffff;
    font-size: 14px;
    transition: all 0.3s ease-out;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.68);
    }
}
