.scrollToTopContainer {
    position: relative;

    .icon {
        position: fixed;
        bottom: 30px;
        right: 25px;
        width: 35px;
        height: 35px;
        padding: 5px;
        z-index: 20;
        border: 1px solid #ffffff;
        border-radius: 50%;
        background-color: #000000;
        color: #ffffff;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &.fadeOut {
            opacity: 0;
            visibility: hidden;
        }

        &.fadeIn {
            opacity: 1;
            visibility: visible;
        }

        &:hover {
            background: #ffffff;
            color: #000000;
            border-color: #000000;
        }
    }
}
