.app {
    text-align: center;
}

button,
a {
    &.orange {
        display: inline-block;
        margin: auto;
        height: 40px;
        width: auto;
        min-width: 150px;
        padding: 0 12px;
        border-radius: 5px;
        font-size: 17px;
        font-weight: bold;
        line-height: 30px;
        text-align: center;
        border: 0;
        user-select: none;
        cursor: pointer;
        background-image: url('/assets/button-orange.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-shadow: -1px -1px 0px #000, 0px -1px 0px #000, 1px -1px 0px #000,
            -1px 0px 0px #000, 1px 0px 0px #000, -1px 1px 0px #000,
            0px 1px 0px #000, 1px 1px 0px #000;

        &:hover {
            transform: scale(1.05);
        }
    }
}

a {
    &.orange {
        line-height: 40px;
    }
}