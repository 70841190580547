.avatarInfo {
    right: 20px;
    top: 10px;
    max-width: 210px;
    margin: 5px auto;
    background-color: #ffffff;

    .userAvatar {
        width: 40px;
        border: 2px solid #000000;
        border-radius: 5px;
    }

    .userLvl {
        display: block;
        height: 12px;
        margin: -12px auto 1px auto;
    }

    .userName {
        margin-top: 4px;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #000;
    }

    button {
        margin: 2px 4px;
        padding: 4px 8px;
        font-size: 12px;
    }
}

@media only screen and (min-width: 580px) {
    .avatarInfo {
        position: absolute;
        width: 110px;
        margin: 0 5px;
    }
}
