.gamesContainer {
    width: 100%;
    margin: 20px auto;
    padding: 10px;

    h2 {
        margin: 30px 0;
        font-size: 40px;
        text-transform: uppercase;
        font-weight: normal;
    }

    .gameCardsContainer {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;
        max-width: 1024px;
        width: 100%;
        margin: 10px auto;
    }
}
